.ms-ai-item-group-block {
    padding: 70px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 38px;
}
.ms-ai-item-group-block-title-area {
    text-align: center;
}
.ms-ai-item-group-block-title {
    color: #4F4E4C;
    text-align: center;
    font-family: "SegoeUI";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin-bottom: 16px;
}
.ms-ai-item-group-block-subtitle {
    color: #0E1726;
    text-align: center;
    font-family: "SegoeUI";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -2px;
}
.ms-ai-item-group-block-tabs {
    background: #ffffff;
    padding: 10px;
    border-radius: 50px;
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
    text-align: center;
    align-items: center;
    margin: auto;
    border: 1px solid #E6F2FB;
}
.ms-ai-item-group-block-tab-item {
    padding: 10px 20px;
    color: #333;
    text-align: center;
    font-family: "SegoeUI";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.3px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    white-space: nowrap; /* Prevent text from wrapping */
    display: flex; /* Enables flexbox */
    align-items: center; /* Vertically centers the text */
    justify-content: center; /* Centers text horizontally */
    height: 40px; /* Set a fixed height to maintain uniformity */
}
.ms-ai-item-group-block-tab-item.active, .ms-ai-item-group-block-tab-item:hover {
    background-color: #06161F;
    color: #FFFFFF;
}
.ms-ai-item-group-block-content-item {
    display: none;
    opacity: 0;
    justify-content: center;
}
.ms-ai-item-group-block-content-item.active {
    display: flex;
    opacity: 1;
}
.ms-ai-item-group-block-tab-contents .ms-ai-inner-page-title-section.false {
    padding: 0 30px;
}