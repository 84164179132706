.ms-ai-item-group-two-block-contents {
    display: flex;
    gap: 20px;
}

.ms-ai-item-group-two-block-left {
    width: 80%;
}

.ms-ai-item-group-two-block-title {
    color: #17253D;
    font-size: 12px;
    text-transform: uppercase;
}

.ms-ai-item-group-two-block-subtitle {
    color: #0E1726;
    font-size: 25px;
    font-weight: 550;
}
.ms-ai-item-group-two-block-podcast {
    padding-bottom: 20px;
}
.ms-ai-item-group-two-block-title-area {
    padding-bottom: 30px;
}
.ms-ai-item-group-two-block-contents {
    padding-left: 40px;
    border-left: 2px solid #702573;
}
.ms-ai-item-group-two-block-left .ms-ai-services-wrapper {
    margin-bottom: 0 !important;
}
.ms-ai-item-group-two-block {
    padding-bottom: 50px;
}