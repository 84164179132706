.mi-ai-stories-win-team-container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .mi-ai-stories-win-team-title {
    font-size: 26px;
    margin-bottom: 10px;
  }
  
  .mi-ai-stories-win-team-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .mi-ai-stories-win-team-sections {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .mi-ai-stories-win-team-section {
    flex: 1;
    margin: 0 10px;
  }
  
  .mi-ai-stories-win-team-heading {
    font-size: 16px;
    margin-bottom: 10px;
    color: #0078D4;
}
  
  .mi-ai-stories-win-team-list {
    list-style-type: none;
    padding: 0;
    margin-left: 10px;
  }
  
  .mi-ai-stories-win-team-list li {
    font-size: 15px;
    margin-bottom: 5px;
    list-style: disc;
}
  
  .mi-ai-stories-win-team-resources {
    margin-top: 30px;
  }
  
  .mi-ai-stories-win-team-resources h3 {
    margin-bottom: 10px;
  }
  
  .mi-ai-stories-win-team-resources ul {
    list-style-type: none;
    padding: 0;
  }
  
  .mi-ai-stories-win-team-resources ul li {
    margin-bottom: 5px;
  }
  
  .mi-ai-stories-win-team-resources ul li a {
    text-decoration: none;
    color: #30446C;
}
  