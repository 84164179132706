@charset "utf-8";
@font-face {
    font-family: 'SegoeUI-Bold';
    src: url('../fonts/segoe/SegoeUI-Bold.eot');
    src: url('../fonts/segoe/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/segoe/SegoeUI-Bold.woff2') format('woff2'), url('../fonts/segoe/SegoeUI-Bold.woff') format('woff'), url('../fonts/segoe/SegoeUI-Bold.ttf') format('truetype'), url('../fonts/segoe/SegoeUI-Bold.svg#youworkforthem') format('svg');
}

@font-face {
    font-family: 'Segoe UI-Bold';
    src: url('../fonts/segoe/SegoeUI-Bold.eot');
    src: url('../fonts/segoe/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/segoe/SegoeUI-Bold.woff2') format('woff2'), url('../fonts/segoe/SegoeUI-Bold.woff') format('woff'), url('../fonts/segoe/SegoeUI-Bold.ttf') format('truetype'), url('../fonts/segoe/SegoeUI-Bold.svg#youworkforthem') format('svg');
}

@font-face {
    font-family: 'SegoeUI';
    src: url('../fonts/segoe/SegoeUI.eot');
    src: url('../fonts/segoe/SegoeUI.eot?#iefix') format('embedded-opentype'), url('../fonts/segoe/SegoeUI.woff2') format('woff2'), url('../fonts/segoe/SegoeUI.woff') format('woff'), url('../fonts/segoe/SegoeUI.ttf') format('truetype'), url('../fonts/segoe/SegoeUI.svg#youworkforthem') format('svg');
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../fonts/segoe/SegoeUI.eot');
    src: url('../fonts/segoe/SegoeUI.eot?#iefix') format('embedded-opentype'), url('../fonts/segoe/SegoeUI.woff2') format('woff2'), url('../fonts/segoe/SegoeUI.woff') format('woff'), url('../fonts/segoe/SegoeUI.ttf') format('truetype'), url('../fonts/segoe/SegoeUI.svg#youworkforthem') format('svg');
}


/* COMMON CSS STARTS */
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    --base-gap: 98px;
    --base-border-radius: 20px;
    --base-container-width: 1600px;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: "Segoe UI", "SegoeUI", sans-serif;;
    background: linear-gradient(90deg, #ece8f6 0%, #f4fafd 49.99%, #dceef8 100%);
}

a,
button,
select,
input,
textarea {
    outline: none;
    text-decoration: none;
}

.ai-container {
    width: 100%;
    max-width: var(--base-container-width);
    margin: 0 auto;
}

.ms-ai-btn {
    background-color: #0078d4;
    border-radius: 8px;
    padding: 10px 20px;
    color: #ffffff;
    font-family: "Helvetica Neue LT W05_65 Medium";
    font-size: 14px;
    line-height: 22px;
}

.ms-bg-green {
    background-color: #23A720;
}

.ms-bg-yellow {
    background-color: #E0C94E;
}

.ms-bg-brick {
    background-color: #CE695B;
}

.ms-ai-assets-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 14px;
}

.innerpage-sub-title {
    color: #0E1726 !important;
    font-family: "Segoe UI", "SegoeUI", sans-serif !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    letter-spacing: -0.6px !important;
    text-align: left;
    margin-bottom: 30px !important;
}

.innerpage-sub-title-light {
    font-family: "Helvetica Neue LT W05_45 Light";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #0E1726;
    margin-bottom: 15px !important;
}

.mw-100 {
    max-width: 100% !important;
}

.bg-green {
    background-color: #7EB900 !important;
}

.bg-orange {
    background-color: #F2B209 !important;
}

.bg-red {
    background-color: #F14F21 !important;
}

/* COMMON CSS ENDS */

.ms-ai-wrapper {
    padding: 15px;
    background: url("../images/ai-solutions-background.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.ms-ai-wrapper .ms-ai-container {}

.ms-ai-wrapper .ms-ai-container .ms-ai-header {
    background-color: #ffffff;
    padding: 15.5px;
    /* width: 100%; */
    cursor: pointer;
    min-height: 54px;
    position: absolute;
    left: 0;
    top: 0;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger {
    display: none;
    align-items: center;
    width: 20px;
    height: 20px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger svg path {
    transition: transform 0.2s ease-in-out;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger.ms-ai-open-nav-menu svg path.cross {
    display: none;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger.ms-ai-open-nav-menu svg path.cross-one {
    transform: rotate(45deg);
    transform-origin: center;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger.ms-ai-open-nav-menu svg path.cross-two {
    display: block;
    transform: rotate(-45deg);
    transform-origin: center;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger svg path.cross-two {
    display: none;
}


.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger svg {
    width: 20px;
    height: auto;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-logo {
    padding-right: 25px;
    display: flex;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-logo img {
    width: 108px;
    max-width: 100%;
    object-fit: contain;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper {
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-left: 2px solid #000000;
    justify-content: space-between;
    width: 100%;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 30px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper ul li {}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper ul li a {
    font-family: "Helvetica Neue LT W05_45 Light";
    color: #262626;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper ul li a.active {
    font-family: "Helvetica Neue LT W05_65 Medium";
    line-height: 23px;
    font-size: 17px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper ul li a span,
.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper .ms-all-microsoft-dd a span {
    width: 8px;
    height: 8px;
    margin-top: 3px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper ul li a span svg,
.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper .ms-all-microsoft-dd a span svg {
    width: 100%;
    height: auto;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper .ms-all-microsoft-dd a {
    font-family: "Helvetica Neue LT W05_45 Light";
    color: #262626;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.ms-ai-hero-wrapper {
    position: relative;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img {
    position: relative;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.4); */
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img {
    max-height: 444px;
    min-height: 444px;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    max-height: 444px;
    min-height: 444px;
}

.ms-ai-hero-wrapper .ms-ai-hero-container {
    position: relative;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container {
    width: 100%;
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    flex: none;
    height: 100%;
    justify-content: center;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-cat-small-title {
    color: #4D4844;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-big-title {
    color: #0E1726;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -3.1px;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-small-title {
    font-family: "Helvetica Neue LT W05_65 Medium";
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 16px;
}

.ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-desc {
    color: #17253D;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-card-list-wrapper {
    padding: var(--base-gap) 0;
    /* padding-bottom: 70px; */
}

.ms-ai-card-cat-block .ms-ai-card-cat-title {
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin-left: 10px;
}

.ms-ai-card-cat-block .ms-ai-card-cat-small-title {
    font-family: "Helvetica Neue LT W05_75 Bold";
    font-size: 17.33px;
    font-weight: 700;
    line-height: 21.16px;
    text-align: left;
    color: #0E1726;
    margin-bottom: 8px;
}

.ms-ai-card-cat-block .ms-ai-card-block {
    /* display: flex;
    gap: 10px;
    flex-wrap: wrap; */
    margin-bottom: 100px;
}

.slide-paddings .slick-initialized .slick-arrow {
    /* display: none !important; */
}

.slide-paddings .slick-initialized .slick-slide {
    padding-right: 10px;
    display: flex;
    height: auto;
}

.ms-ai-slider-wrapper.ms-ai-small-card .slide-paddings .slick-initialized .slick-slide {
    display: inline-block;
}

.slick-slide>div:first-child {
    width: 100%;
    display: flex;
    height: 100%;
}

.slide-paddings .slick-initialized .slick-track {
    margin-left: 0;
    height: 100%;
    padding-bottom: 2px;
    display: flex !important;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item.ms-ai-slider-img-contain .ms-ai-slider-img-block img {
    object-fit: contain !important;
}

a.coming-soon-item {
    opacity: 0.5;
}

.ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item {
    position: relative;
    /* aspect-ratio: 16/9; */
    /* width: calc((var(--base-container-width) / 8) - 9px); */
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.06);
    overflow: hidden;
    width: 100%;
    height: 226px;
}

.ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item .ms-ai-card-bg-img {
    height: 100%;
}

.ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item:hover .ms-ai-card-bg-img img {
    transform: scale(1.1);
}

.ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item .ms-ai-card-bg-img img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
    transform: scale(1);
}

.ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item .ms-ai-card-name {
    font-family: "Helvetica Neue LT W05_65 Medium";
    font-size: 15px;
    color: #ffffff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    padding: 0 20px;
    text-align: center;
    line-height: 17px;
    z-index: 2;
}

.ms-ai-image-card-logo {
    width: 50%;
    margin: auto;
}

.coming-soon-item:hover .ms-ai-card-name span {
    display: none;
}

.coming-soon-item:hover .ms-ai-card-item .ms-ai-image-card-logo img {
    opacity: 0.1;
}

/* .coming-soon-item:hover .ms-ai-card-name:before {
    content: "Coming Soon"
} */

.coming-soon-item .ms-ai-card-name:before {
    content: "Coming Soon";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.coming-soon-item:hover .ms-ai-card-name:before {
    opacity: 1;
}

.ms-ai-logo-image-card .ms-ai-card-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0e2841;
    /* Default background color */
    padding: 10px;
    border-radius: 5px;
}

.ms-ai-logo-image-card .ms-ai-card-bg-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    overflow: hidden;
    padding: 10px;
}

.ms-ai-logo-image-card .ms-ai-card-bg-img img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    /* Ensures the image covers the area while maintaining aspect ratio */
}

.ms-ai-logo-image-card .ms-ai-card-name {
    text-align: center;
    color: white;
    /* Adjust text color as needed */
    font-size: 1.2em;
}

/* .ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item .ms-ai-card-bg-img img {
    object-fit: contain;
} */

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper {
    padding: 0;
    padding-top: 55px;
    padding-bottom: 150px;
    min-height: calc(100vh - 72px);
}

/* .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container {
    display: flex;
    gap: 50px;
} */

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left {
    /* max-width: 335px;
    width: 100%; */
    width: 250px;
    min-width: 250px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul {}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li>ul,
.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li>ul li>ul {
    margin-left: 25px;
    margin-left: 25px;
    max-height: 0;
    /* opacity: 0; */
    display: none;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul.open-menu {
    /* max-height: 100vw;
    opacity: 1; */
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li {
    /* margin-bottom: 5px; */
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li:last-child {
    margin-bottom: 0;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a {
    padding: 7px;
    width: 100%;
    /* max-width: 235px; */
    color: #676A6C;
    font-family: "Helvetica Neue LT W05_65 Medium";
    font-size: 15px;
    line-height: 20px;
    display: flex;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    justify-content: space-between;
    margin-bottom: 12px;
    text-transform: capitalize;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a span {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a.active.open-menu~ul {
    max-height: 100%;
    /* opacity: 1; */
    display: block;
    visibility: visible;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a.active,
.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a:hover {
    background-color: #F6F6F6;
    color: #000000;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a span svg {
    transition: transform 0.3s ease-in-out;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a.active:not(.open-menu) span svg {
    transform: rotate(180deg);
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul li a:not(.active) span svg {
    transform: rotate(180deg);
}


.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right {
    /* width: calc(100% - 290px); */
    position: relative;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-search-wrapper {
    /* position: absolute; */
    bottom: 150px;
}

.ms-ai-inner-page-main-title {
    color: #0E1726;
    text-align: center;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: -2.4px;
    text-transform: capitalize;
    margin-bottom: 30px;
}

.ms-ai-inner-page-main-desc {
    margin-bottom: 10px;
    width: 100%;
    max-width: 750px;
    margin-bottom: 20px;
    color: #17253D;
    text-align: center;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
    width: 100%;
    max-width: 840px;
}

.ms-ai-card-list-wrapper-home .ms-ai-inner-page-main-desc,
.ms-ai-card-list-wrapper-home .ms-ai-inner-page-main-title {
    text-align: center;
}

.ms-ai-inner-page-main-desc p {
    margin: 0;
}

.ms-ai-inner-page-main-desc p {
    color: #17253D;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.48px;
    text-align: left;
}

.ms-ai-inner-page-sub-headeing {
    font-family: "Helvetica Neue LT W05_75 Bold";
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -1px;
    color: #0E1726;
    margin-bottom: 20px;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block {
    /* display: flex;
    gap: 16px; */
    margin-bottom: 25px;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item {
    background-color: #FEFEFE;
    box-shadow: 0px 0px 1.44444px rgba(0, 0, 0, 0.12), 0px 1.44444px 2.88889px rgba(0, 0, 0, 0.14);
    border-radius: 17.3333px;
    /* width: calc(100% / 4); */
    /* max-width: 312px; */
    padding: 6px;
    padding-bottom: 15px;
    /* display: flex;
    flex-direction: column; */
    cursor: pointer;
    height: 100%;
    display: inline-block;
    float: left;
    clear: both;
    /* margin-bottom: 40px; */
    position: relative;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item .ms-ai-slider-img-block img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    /* aspect-ratio: 16 / 9; */
    border-radius: 10px;
    height: 226px;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item .ms-ai-slider-title {
    color: #0E1726;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.6px;
    padding: 0 10px;
    text-transform: capitalize;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item .ms-ai-slider-desc {
    color: #17253D;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.48px;
    padding: 0 10px;
    padding-bottom: 140px;
    min-height: 100px;
    flex: 1;
}

.ms-ai-item-group-block .ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item a.ms-ai-learnmore span svg rect {
    fill: #0078D4;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item a.ms-ai-learnmore span {
    color: #3E143F;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.48px;
    display: flex;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item a.ms-ai-learnmore span svg {
    width: 35px;
    height: auto;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item a.ms-ai-learnmore {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    padding: 0 10px;
    position: absolute;
    bottom: 15px;
}

.ms-ai-slider-wrapper.ms-ai-blog-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item a.ms-ai-learnmore {
    position: static;
}

.ms-ai-slider-wrapper.ms-ai-small-card .ms-ai-slider-container .ms-ai-slider-block {
    flex-wrap: wrap;
}

/* .ms-ai-slider-wrapper.ms-ai-small-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item {
    max-width: 222px;
    min-width: 222px;
} */

.ms-ai-slider-wrapper.ms-ai-small-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item .ms-ai-slider-title {
    color: #0E1726;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -1.2px;
    min-height: 120px;
    margin-top: 5px;
}

.ms-ai-slider-wrapper.ms-ai-small-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item a.ms-ai-learnmore span {
    color: #2A446F;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.48px;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item.ms-ai-big-card .ms-ai-slider-img-block {
    /* padding: 0 10px; */
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item.ms-ai-big-card .ms-ai-slider-img-block img {
    /* width: auto;
    height: 90px;
    object-fit: contain;
    display: flex;
    width: auto; */
    aspect-ratio: 16 / 9;
    border-radius: 10px;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
}

.ms-ai-slider-wrapper.ms-ai-big-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item .ms-ai-slider-img-block {
    padding: 0 10px;
}

.ms-ai-slider-wrapper.ms-ai-big-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item .ms-ai-slider-img-block img {
    width: auto;
    height: 90px;
    object-fit: contain;
    display: flex;
    width: auto;
}



.ms-ai-services-wrapper {
    margin: 25px 0;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 900px;
}

.ms-ai-services-wrapper .ms-ai-services-block {
    /* width: 100%;
    max-width: 260px; */
}

.ms-ai-services-wrapper .ms-ai-services-block .ms-ai-services-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    /* max-width: 280px; */
    max-width: 100%;
    /* margin-bottom: 8px; */
}

.ms-ai-services-wrapper .ms-ai-services-block .ms-ai-services-item .ms-ai-services-logo {
    width: 50px;
    height: 50px;
    min-width: 50px;
    background-color: #ffffff;
    border-radius: 6.4px;
    border: 1px solid #E6F2FB;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ms-ai-services-wrapper .ms-ai-services-block .ms-ai-services-item .ms-ai-services-title {
    font-family: "Helvetica Neue LT W05_65 Medium";
    font-size: 14px;
    line-height: 18px;
    color: #0E1726;
}

.ms-ai-services-wrapper .ms-ai-services-block .ms-ai-services-progressbar {
    width: 100%;
    display: flex;
}

.ms-ai-services-wrapper .ms-ai-services-block .ms-ai-services-progressbar span {
    width: 100%;
    height: 4px;
    border-radius: 12px;
}

.ms-ai-circle-graph-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.ms-ai-circle-graph-wrapper .ms-ai-circle-graph-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ms-ai-circle-graph-wrapper .ms-ai-circle-graph-item .ms-ai-circle-graph-title {
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.ms-ai-circle-graph-wrapper .ms-ai-circle-graph-item .ms-ai-circle-graph-status {
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2px 10px;
    border-radius: 5px;
}

.ms-ai-download-wrapper .ms-ai-download-container .ms-ai-download-item {
    width: fit-content;
    margin-bottom: 25px;
}

.ms-ai-download-wrapper .ms-ai-download-container .ms-ai-download-item .ms-ai-download-item-title {
    font-family: "Helvetica Neue LT W05_75 Bold";
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.3px;
    text-align: left;
    color: #0E1726;
}

.ms-ai-download-wrapper .ms-ai-download-container .ms-ai-download-item a {
    font-family: "Helvetica Neue LT W05_75 Bold";
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.3px;
    color: #2A446F;
    display: flex;
    align-items: center;
    gap: 10px;
}


/* .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page {
    max-width: 700px;
} */

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 15px 20px;
    /* overflow: hidden; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper.ms-ai-no-navigation {
    margin-bottom: 30px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper.ms-ai-no-navigation .ms-ai-blog-next-prev-btn-wrapper {
    display: none;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper {
    position: absolute;
    bottom: 0;
    right: -120px;
    display: flex;
    gap: 10px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper a,
.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper button {
    display: flex;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper a.ms-ai-btn-disabled svg,
.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper button.ms-ai-btn-disabled svg {
    opacity: 0.4;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper a.ms-ai-btn-disabled,
.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper button.ms-ai-btn-disabled {
    pointer-events: none;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-desc {
    font-family: "Helvetica Neue LT W05_75 Bold";
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 25px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-content {
    margin-bottom: 30px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-content .ms-ai-blog-content-title {
    font-family: "Helvetica Neue LT W05_65 Medium";
    font-size: 18px;
    line-height: 22x;
    color: #575867;
    margin-bottom: 15px;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-content .ms-ai-blog-content-paragraph {
    font-family: "Helvetica Neue LT W05_45 Light";
    font-size: 15px;
    line-height: 20x;
    color: #575867;
    margin-bottom: 15px;
}


/* .ms-ai-blog-page .ms-ai-slider-wrapper.ms-ai-small-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item {
    max-width: 165px;
    min-width: 165px;
    padding-bottom: 6px;
    border-radius: 12px;
} */

.ms-ai-blog-page .ms-ai-slider-wrapper.ms-ai-small-card .ms-ai-slider-container .ms-ai-slider-block {
    gap: 12px;
}

.ms-ai-blog-page .ms-ai-slider-wrapper.ms-ai-small-card.ms-ai-blog-card .ms-ai-slider-container .ms-ai-slider-block {
    /* display: flex; */
}

.ms-ai-slider-wrapper.ms-ai-small-card.ms-ai-blog-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item {
    padding: 7px;
    padding-bottom: 5px;
    border-radius: 10px;
}

.ms-ai-blog-page .ms-ai-slider-wrapper.ms-ai-small-card .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item a.ms-ai-learnmore {
    padding: 0;
}


.question-bar {
    width: 100%;
    padding: 15px 20px;
    background-color: #ffffff;
    box-shadow: 0 2.3px 2.2px #00000003, 0 5.3px 5.2px #00000003, 0 9.6px 9.4px #00000003, 0 16px 15.7px #00000005, 0 26.4px 25.9px #00000005, 0 46.2px 45.3px #00000005, 0 100px 98px #00000008;


    position: fixed;
    bottom: 0;
    left: 0px;
    width: 100%;
    margin: 0 auto;
    right: 0px;
    z-index: 3;
    /* transition: all 0.7s ease-in-out; */
}

.question-bar .question-bar-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    max-width: 1695px;
    margin: 0 auto;
}

.question-bar .input-wrapper {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    flex: 1 0 0;
    border-radius: 50px;
    border: 1px solid #3C19CD;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #767676;
    position: relative;
}

.question-bar .input-wrapper .action-btns {
    display: flex;
    gap: 10px;
    align-items: center;
}

.question-bar .input-wrapper .action-btns>div {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.question-bar .input-wrapper .action-btns>div>svg {
    width: 18px;
    height: auto;
}

.question-bar .input-wrapper label {
    color: #0067B8;
    white-space: nowrap;
}

.question-bar .input-wrapper input {
    align-self: stretch;
    flex: 1 0 0;
    border: none;
    background: none;
    outline: none;
    font-family: 'Roboto';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
}

.question-bar .send,
.question-bar .microphone,
.question-bar .home,
.question-bar .three-dots {
    display: flex;
    cursor: pointer;
}

.question-bar .home {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question-bar .send {
    transition: all .2s ease-in-out;
    transform: scale(1);
}

.question-bar .send:active {
    transform: scale(0.9);
}

.question-bar .send svg,
.question-bar .microphone svg {
    width: 35px;
    height: auto;

}

.question-bar .home svg {
    width: 18px;
    height: auto;
}


.ms-ai-inner-page-back-btn {
    cursor: pointer;
    margin-bottom: 12px;
}

/* slick arrows start*/

.slick-next:before,
.slick-prev:before {
    content: "" !important;
}

.slick-next,
.slick-prev {
    width: 40px !important;
    height: 40px !important;
    z-index: 9999;
}

.slick-prev {
    left: 10% !important;
}

.slick-next {
    right: 10% !important;
}

/* .home-slider-cards .slick-next svg path, .home-slider-cards .slick-prev svg path {
    stroke: #555;
} */
.home-slider-cards .slick-disabled {
    opacity: 0;
}

.ms-ai-slider-container button.slick-arrow {
    height: 100% !important;
    width: 100px !important;
}

.ms-ai-slider-container button.slick-arrow svg {
    width: 40px;
}

.ms-ai-slider-container .slick-prev {
    left: 0 !important;
    background: linear-gradient(86.27deg, #F1F2FA 2.74%, rgba(243, 247, 252, 0) 96.67%);
}

.ms-ai-slider-container .slick-next {
    right: 0 !important;
    background: linear-gradient(86.31deg, rgba(227, 242, 250, 0) 2%, #DDEFF9 70%);
}

.home-slider-cards .slick-next {
    right: -20px !important;
}

.home-slider-cards .slick-prev {
    left: -20px !important;
}

.ms-ai-logo-image-card .slick-next {
    right: -32px !important;
}

.ms-ai-logo-image-card .slick-prev {
    left: -40px !important;
}

.ms-ai-logo-image-card .slick-prev svg path,
.ms-ai-logo-image-card .slick-next svg path {
    stroke: #000;
}

.ms-ai-slider-container .slick-prev:hover,
.ms-ai-slider-container .slick-prev:focus {
    background: linear-gradient(86.27deg, #F1F2FA 2.74%, rgba(243, 247, 252, 0) 96.67%);
}

.ms-ai-slider-container .slick-next:hover,
.ms-ai-slider-container .slick-next:focus {
    background: linear-gradient(86.31deg, rgba(227, 242, 250, 0) 2%, #DDEFF9 70%);
}

.slick-next svg,
.slick-prev svg {
    width: 100%;
    height: auto;
}

.slick-next svg path,
.slick-prev svg path {
    stroke: #fff;
}

.ms-ai-slider-container .slick-next svg path,
.ms-ai-slider-container .slick-prev svg path {
    stroke: #000;
}

.slick-disabled {
    opacity: 0.1;
}

.ms-ai-slider-container .slick-disabled {
    opacity: 0;
    display: none !important;
}

/* slick arrows end*/

/* loading start */
.tc-loading-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tc-loading-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.tc-loading-spinner div {
    transform-origin: 40px 40px;
    animation: tc-loading-spinner 1.2s linear infinite;
}

.tc-loading-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #000;
}

.tc-loading-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.tc-loading-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.tc-loading-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.tc-loading-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.tc-loading-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.tc-loading-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.tc-loading-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.tc-loading-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.tc-loading-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.tc-loading-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.tc-loading-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.tc-loading-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

button.ms-ai-listen-btn {
    background-color: #0177D3;
    color: #fff;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

span.ms-ai-podcast-beta {
    background: #fefefe;
    padding: 4px 6px;
    border-radius: 6px;
    margin-left: 10px;
    font-size: 14px;
    color: #676767;
    font-family: "Helvetica Neue";
}

.ms-ai-audio-player-background .ms-ai-podcast-beta {
    position: absolute;
    top: 0;
    right: -60px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

button.ms-ai-listen-btn:hover {
    background-color: #0381e2;
}

.ms-ai-inner-page-title-btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 15px;
}

@keyframes tc-loading-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ms-ai-tags-container .ms-ai-tag-item {
    background-color: #E9E9E9;
    border-radius: 6px;
    padding: 3px 6px;
}

.ms-ai-tags-container {
    position: absolute;
    left: 15px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    gap: 5px;
    bottom: 10px;
    font-weight: 500;
    color: #17253D;
    align-items: start;
    padding-bottom: 50px;
}

@keyframes teams-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    width: 40px;
    /* Adjust size as needed */
    height: 40px;
    display: inline-block;
    border: 4px solid transparent;
    /* Transparent for the broken effect */
    border-top-color: #6264a7;
    /* Teams' primary color */
    border-right-color: #6264a7;
    /* Optional: add second color */
    border-radius: 50%;
    animation: teams-spinner 1s linear infinite;
}

/* loading end */


/* New Design CSS Start */
.ms-ai-home-title-container {
    width: 100%;
    max-width: 967px;
    margin: auto;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider-container.slide-paddings.home-slider-cards {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: thin;
    /* Firefox */
    align-items: stretch;
    background: transparent;
    border-radius: 10px;
    padding-bottom: 25px;
    padding-left: 3px;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-slider-item {
    max-width: 280px;
    min-width: 280px;
    height: auto;
    min-height: 275px;
}

/* Scrollbar Styles for WebKit Browsers (Chrome, Safari, Edge) */
.slider-container.slide-paddings.home-slider-cards::-webkit-scrollbar {
    height: 6px;
    /* Scrollbar thickness */
}

.slider-container.slide-paddings.home-slider-cards::-webkit-scrollbar-track {
    background: #FEFEFE;
    /* Scrollbar track background */
    border-radius: 10px;
}


.slider-container.slide-paddings.home-slider-cards::-webkit-scrollbar {
    height: 6px;
    /* Adjust thickness */
}

.slider-container.slide-paddings.home-slider-cards::-webkit-scrollbar-thumb {
    background: #702573;
    /* Scrollbar color */
    border-radius: 10px;
}

.slider-container.slide-paddings.home-slider-cards {
    scrollbar-color: #702573 #FEFEFE;
    /* Thumb (red) | Track (light gray) */
}

.ms-ai-card-container .add-new-item {
    height: 100%;
}

.ms-ai-card-container {
    background: #FEFEFE;
    padding: 8px;
    border-radius: 12px;
    min-width: 300px;
    min-height: 100%;
    position: relative;
    border-radius: 24px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
}

.ms-ai-card-container .ms-ai-card-item-category {
    color: #8C331F;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.48px;
    /* margin-bottom: 8px; */
}

.ms-ai-card-container .ms-ai-card-name {
    padding: 24px 20px 80px 20px;
}

.ms-ai-card-container .ms-ai-card-item-title {
    color: #0E1726;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.6px;
    min-height: 50px;
}

span.ms-ai-card-btn-arrow {
    background: #702573;
    width: 35px;
    height: 35px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

span.ms-ai-card-btn-arrow svg {
    width: 15px;
    height: auto;
}

.ms-ai-card-item-learn-more {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    /* Ensures it doesn't stretch */
    height: fit-content;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
}

span.ms-ai-card-btn-text {
    color: #3E143F;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.48px;
    padding-left: 8px;
}


.ms-ai-card-container .add-new-item .ms-ai-card-name {
    background-color: #FEFEFE;
}

.ms-ai-card-container .add-new-item .ms-ai-card-name span {
    color: #555555;
}

.add-new-item .ms-ai-card-item {
    width: 100%;
    height: 100%;
}

/* Menu Container */
.ms-ai-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Menu Grid - 6 items per row */
.ms-ai-menu {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* Always 6 items per row */
    gap: 2px;
    width: 100%;
    height: 100%;
    padding-bottom: 67px;
}

span.ms-ai-title-main-page-name {
    color: #17253D;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.ms-ai-menu a {
    color: #0E1726;
}

/* Menu Item */
.ms-ai-menu-item {
    padding: 10px;
    background: #f5f5f5;
    line-height: 40px;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.3px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
}

/* Hover Effect */
.ms-ai-menu-item:hover {
    background: #ffffff;
}

/* Active Item */
.ms-ai-menu-active {
    border-bottom: 2px solid purple;
    font-weight: bold;
}

/* First item in each row */
.ms-ai-menu-item:nth-child(6n + 1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* Last item in each row */
.ms-ai-menu-item:nth-child(6n) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* If the last row has fewer than 6 items, only the first item gets border-radius */
.ms-ai-menu-item:last-child:nth-child(6n + 1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ms-ai-inner-page-title-section.ms-ai-inner-page-main {
    border-left: 4px solid #702573;
    padding-left: 48px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 60px;
}

.ms-ai-inner-page-main-desc.ms-ai-left-area-description {
    padding-right: 30px;
}

.ms-ai-title-small {
    font-size: 12px !important;
    font-weight: 500;
    text-transform: uppercase;
}

.ms-ai-slider-tags {
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* padding-bottom: 37px; */
}

span.ms-ai-tag-filter-item {
    background: rgba(0, 85, 151, 0.15);
    padding: 10px 20px;
    border-radius: 40px;
    cursor: pointer;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.3px;
}

span.ms-ai-tag-filter-item:hover,
span.ms-ai-tag-filter-item.active {
    background: rgba(0, 85, 151, 1);
    color: #fff;
}

.ms-ai-small-card .ms-ai-slider-item {
    min-height: 200px;
    padding: 10px !important;
    border-radius: 10px !important;
}

.ms-ai-small-card .ms-ai-slider-item a {
    color: #2A446F !important;
    font-weight: 500;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
}

.ms-ai-slider-title-head {
    color: #005597;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.48px;
    padding: 10px 0 0 10px;
}

.ms-ai-slider-item-icon {
    padding-left: 10px;
}

.ms-ai-slider-item-icon svg {
    width: 32px;
    height: auto;
}

/* Make sure all titles have the same width */
.ms-ai-circle-graph-title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    text-align: center;
    display: inline-block;
    width: 100%;
    /* Ensure it takes full width */
    text-align: center;
    padding: 5px 0;
}

/* Make sure all graph statuses align properly */
.ms-ai-circle-graph-status {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    padding: 8px 15px;
    border-radius: 20px;
    display: inline-block;
    min-width: 80px;
    text-align: center;
}

/* Ensure uniform title widths using flexbox */
.ms-ai-circle-graph-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    min-width: 200px;
    border-left: 4px solid #0078D4;
    padding-left: 10px;
}

/* Ensures all titles take the width of the longest title */
.ms-ai-circle-graph-title {
    display: flex;
    min-width: 180px;
    /* Set a reasonable minimum width */
    max-width: 100%;
    white-space: nowrap;
}

.ms-ai-circle-graph-wrapper {
    flex-direction: column !important;
}


.ms-ai-card-image-item {
    background-color: #FEFEFE;
    box-shadow: 0px 0px 1.44444px rgba(0, 0, 0, 0.12), 0px 1.44444px 2.88889px rgba(0, 0, 0, 0.14);
    border-radius: 17.3333px;
    /* width: calc(100% / 4); */
    /* max-width: 312px; */
    padding: 6px;
    padding-bottom: 15px;
    cursor: pointer;
    /* height: 100%; */
    display: inline-block;
    float: left;
    clear: both;
    margin-bottom: 40px;
    position: relative;
    min-width: 300px;
    max-width: 300px;
}

.ms-ai-item-group-block-tab-contents {
    width: 100%;
}

.ms-ai-card-image-item .ms-ai-card-bg-img img {
    width: 100%;
    border-radius: 10px;
}

.slider-container.slide-paddings.home-slider-cards.ms-justify-center {
    justify-content: center;
}

.ms-ai-card-image-sub-name {
    padding-top: 30px;
    color: #8C331F;
    font-size: 12px;
    padding-left: 15px;
}

.ms-ai-image-card-container .ms-ai-card-name {
    font-size: 20px;
    font-weight: 501;
    padding-left: 15px;
}

.ms-ai-learnmore span svg rect {
    fill: #702573;
}

.ms-ai-image-card-container .ms-ai-card-item-wrapper a.ms-ai-learnmore span {
    font-family: "SegoUI";
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.35px;
    color: #3E143F;
    display: flex;
}

.ms-ai-image-card-container .ms-ai-card-item-wrapper a.ms-ai-learnmore {
    display: flex;
    align-items: center;
    gap: 5px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 30px 15px 20px 15px;
    bottom: 10px;
}


/* Wrapper to create a 3-column grid */
.ms-ai-services-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns with equal width */
    gap: 20px;
    /* Adds spacing between columns */
}

.ms-ai-list-view-container .ms-ai-services-block {
    border-left: 3px solid blue;
    padding-left: 20px;
    line-height: 50px;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-left: 4px solid #0078D4;
    /* Left border color */
}

.ms-ai-list-view-container .ms-ai-services-block .ms-ai-services-item {
    line-height: 40px;
}

.ms-ai-card-slider-block-title {
    font-size: 30px;
    font-weight: 500;
}

.padding-tb-40 {
    padding: 40px 0;
}

.padding-b-40 {
    padding-bottom: 40px;
}

.padding-t-40 {
    padding-top: 40px;
}

.ms-ai-item-group-block-content-item .ms-ai-inner-page-main-desc.ms-ai-left-area-description {
    padding: 0 40px;
}

.ms-ai-blog-page .ms-ai-blog-img-wrapper,
.ms-ai-blog-page .ms-ai-blog-content {
    max-width: 700px;
}

.c-uhff {
    margin-top: 0 !important;
}

.ms-ai-full-width {
    width: 100%;
}

.c-uhfh .theme-light .c-uhf-menu>button+ul {
    right: 0 !important;
    z-index: 9999999999;
}

span.ms-ai-tag-filter-item.ms-tag-data-notexist {
    color: #555;
}

span.ms-ai-tag-filter-item.ms-tag-data-notexist:hover {
    background: rgba(0, 85, 151, 0.15);
}

.slider-container.slide-paddings.home-slider-cards.horizontal-scroll-container.card-data-slider-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
}

button.ms-ai-card-cat-show-all-btn {
    background: #091F2C;
    color: #FFFFFF;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 15px;
    padding: 8px 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.ms-ai-card-cat-title-btn-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.ms-ai-card-cat-title {
    flex-grow: 1;
}

.ms-ai-card-cat-show-all-container {
    justify-content: flex-end;
    display: flex;
}

.ms-ai-show-as-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

.pb-37 {
    padding-bottom: 37px !important;
}

.ms-ai-slider-wrapper .ms-ai-slider-container .ms-ai-slider-block .ms-ai-show-as-grid .ms-ai-slider-item {
    max-width: 100% !important;
}

/* New Design CSS End */

.c-uhfh .theme-light .c-uhf-menu>button+ul {
    right: 0;
}

@media (max-width: 1920px) {}

@media (max-width: 1440px) {
    .ms-ai-inner-page-main-desc {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 12px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-cat-small-title {
        margin-bottom: 15px;
    }

    .ms-hero-bg-img {
        background: #e8d7cb;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img,
    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img img {
        min-height: inherit;
        max-height: 300px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container {
        max-width: 900px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-big-title {
        font-size: 50px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-small-title {
        margin-bottom: 10px;
        font-size: 15px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-desc {
        max-width: inherit;
        margin-bottom: 15px;
        font-size: 15px;
        line-height: normal;
    }

    .ms-ai-inner-page-main-title {
        font-size: 50px;
        margin-bottom: 15px;
    }

    .ms-ai-btn {
        line-height: normal;
        font-size: 12px;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item {
        /* width: calc((var(--base-container-width) / 10) - 9px); */
    }

    .ms-ai-card-cat-block .ms-ai-card-block .ms-ai-card-item .ms-ai-card-name {
        line-height: 15px;
        font-size: 13px;
    }

    .ms-ai-card-cat-block .ms-ai-card-cat-title {
        font-size: 10px;
    }

    .ms-ai-card-cat-block .ms-ai-card-block {
        margin-bottom: 20px;
    }
}

@media (max-width: 1240px) {
    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container {
        gap: 40px;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper {
        margin-bottom: 60px;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .ms-ai-blog-page .ms-ai-blog-img-wrapper .ms-ai-blog-next-prev-btn-wrapper {
        right: 0;
        bottom: -50px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container {
        max-width: 700px;
    }
}

@media (max-width: 991px) {
    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container {
        max-width: 500px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img,
    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img img {
        max-height: 280px;
        min-height: 280px;
    }
}

@media screen and (min-width: 860px) {
    .ms-ai-wrapper {
        padding: 0 8%;
    }
}

@media only screen and (min-width: 860px) and (max-width: 1083px) {
    .ms-ai-wrapper {
        padding: 0 24px;
    }
}

@media (max-width: 767px) {
    .ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-mob-harmburger {
        display: flex;
    }

    .ms-ai-inner-page-back-btn {
        display: none;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left {
        width: auto;
        min-width: inherit;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right {
        width: 100%;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container {
        gap: 0;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul.ms-ai-nav-menu-wrapper {
        position: fixed;
        top: 54px;
        left: -350px;
        padding: 20px;
        background-color: #ffffff;
        box-shadow: 1px 1px 1.58727px rgba(0, 0, 0, 0.12), 1px 0.5px 3.17453px rgba(0, 0, 0, 0.14);
        border-radius: 0px 12px 12px 0px;
        transition: left 0.5s ease-in-out;
        max-height: calc(100% - 54px);
        overflow-y: auto;
        max-width: 350px;
        z-index: 999;
        height: 100%;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-left ul.ms-ai-nav-menu-wrapper.ms-ai-open-nav-menu {
        left: 0;
        border-radius: 0;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img {
        max-height: 430px;
        min-height: 430px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-hero-bg-img img {
        max-height: inherit;
        min-height: inherit;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container {
        max-width: 100%;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-cat-small-title {
        margin-bottom: 25px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-big-title {
        font-size: 38px;
        line-height: 43px;
        text-align: center;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-small-title {
        font-size: 17px;
        margin-bottom: 70px;
    }

    .ms-ai-hero-wrapper .ms-ai-hero-container .ms-ai-hero-slider-wrapper .ms-ai-hero-slider-container .ms-ai-hero-slider-item .ms-page-desc {
        font-size: 17px;
        line-height: 27px;
        margin-bottom: 30px;
    }

    .ms-ai-btn {
        padding: 15px 17px;
        font-size: 14px;
        border-radius: 7px;
    }

    .ms-ai-inner-page-main-title {
        line-height: 30px;
        text-align: center;
        margin-bottom: 18px;
    }

    .ms-ai-inner-page-main-desc {
        line-height: 23px;
        text-align: center;
    }

    .ms-ai-card-cat-block .ms-ai-card-cat-title {
        font-size: 10px;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper ul li a.active {
        font-size: 13px;
        line-height: 15px;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-logo {
        padding-right: 0;
    }

    .ms-ai-wrapper .ms-ai-container .ms-ai-header .ms-ai-header-container .ms-ai-nav-wrapper {
        padding-left: 20px;
    }

    .innerpage-sub-title {
        text-align: left;
    }

    .ms-ai-inner-page-main-desc {
        text-align: left;
    }

    .ms-ai-inner-page-main-desc p {
        text-align: left;
    }

    .ms-ai-circle-graph-wrapper .ms-ai-circle-graph-item {
        width: 100%;
        justify-content: space-between;
    }

    .ms-ai-services-wrapper .ms-ai-services-block {
        width: 48%;
    }

    .ms-ai-services-wrapper .ms-ai-services-block .ms-ai-services-item .ms-ai-services-logo {
        width: 36px;
        height: 36px;
        min-width: 36px;
    }

    .ms-ai-services-wrapper .ms-ai-services-block .ms-ai-services-item .ms-ai-services-title {
        font-size: 12px;
    }

    .ms-ai-services-wrapper {
        justify-content: space-between;
        gap: 0px;
    }

    .slick-prev,
    .slick-next {
        display: none !important;
    }

    .slick-dots {
        bottom: 30px !important;
    }

    .slick-dots li button:before {
        color: #ffffff !important;
    }

    .slick-dots li button:before {
        font-size: 8px;
    }

    .slick-dots li.slick-active button:before {
        font-size: 10px;
    }

    .slick-dots li {
        width: 15px;
        height: 15px;
    }

    .slick-dots li button:before {
        opacity: 1 !important;
    }
}