.mi-ai-stories-arch-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.mi-ai-stories-arch-title {
    margin-bottom: 20px;
}

.mi-ai-stories-arch-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 40px;
}

.mi-ai-stories-arch-content, .mi-ai-stories-arch-image-container {
    flex: 1;
    padding: 10px;
}

.mi-ai-stories-arch-subtitle {
    font-size: 16px;
    margin-bottom: 10px;
    color: #0078D4;
}

.mi-ai-stories-arch-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    font-family: "Helvetica Neue LT W05_45 Light";
  font-size: 15.84px;
  line-height: 24px;
  color: #17253D;
}
.mi-ai-stories-arch-list li {
    margin-bottom: 6px;
}
.mi-ai-stories-arch-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mi-ai-stories-arch-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 24px 0 #00000054;
}

.mi-ai-stories-arch-image-container-santender {
    text-align: center;
}
.mi-ai-stories-arch-image-container-santender img {
    width: 80%;
}