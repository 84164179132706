.ms-ai-inner-page-right.ms-ai-search-result {
    font-family: "Segoe UI", "SegoeUI", sans-serif;
}
.ms-ai-search-result .ms-ai-question {
    font-size: 32px;
}
.ms-ai-search-summary-heading {
    font-size: 20px;
    display: flex;
    margin-top: 30px;
    gap: 5px;
    align-items: center;
}
.ms-ai-search-summary-desc {
    font-size: 16px;
    margin-top: 20px;
    font-weight: 200;
}
.ms-ai-search-list-title {
    font-size: 16px;
    font-weight: 600;
}
.ms-ai-search-list-title span{
    display: flex;
    height: 10px;
}
.ms-ai-search-list-title a{
    line-height: 20px;
    margin-top: -4px;
}
.ms-ai-search-list-title .ms-ai-search-list-title-container{
    display: flex;
    align-items: center;
    gap: 4px;
}
.ms-ai-search-list-desc {
    font-size: 16px;
    font-weight: 200;
}
.ms-ai-search-list-title {
    display: flex;
    align-items: center;
}
.ms-ai-search-back {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    gap: 5px;
}
.ms-ai-search-back-text {
    font-size: 16px;
    font-weight: 100;
}
.ms-ai-search-summary ol {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ms-ai-search-summary ol li::marker {
    /* content: "1";
    font-size: 1.2em;
    color: red; */
  }
.ms-ai-search-summary ol li .ms-ai-search-title{
    display: flex;
}
.ms-ai-search-summary ol.ms-ai-search-result-list{
    list-style: none;
    padding-left: 10px;
}
.ms-ai-list-count{
    min-width: 35px;
    text-align: right;
    padding-right: 8px;
    max-width: 35px;
}
.ms-ai-search-summary ol li .ms-ai-search-title .ms-ai-icon{
    display: flex;
    margin-right: 5px;
}
.ms-ai-search-summary ol li .ms-ai-search-title .ms-ai-icon svg{
    width: 20px;
    height: auto;
}
.ms-ai-search-summary ol li .ms-ai-search-title .ms-ai-search-title-link a{
    color: rgba(0,0,0,0.9);
    text-decoration: none;
    cursor: pointer;
    font-family: "Segoe UI", "SegoeUI", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ms-ai-search-result .ms-ai-address-bar-container {
    max-width: 100% !important;
}
.ms-ai-search-wrapper {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    max-width: 820px;
}
.ms-ai-search-list-title-container a {
    color: rgba(0,0,0,0.9);
}