/* Background styling */
.ms-ai-audio-player-background {
    background-image: url("../../assets/images/podcast.png");
    background-size: cover;
    background-position: center;
    text-align: center;
    position: absolute;
    right: -300px;
    top: 56px;
    width: 280px;
    height: 150px;
}
.ms-ai-audio-player-bg {
    align-items: center;
    background: #00000090;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    width: 100%;
}
.ms-ai-inner-page-main-desc {
    position: relative;
}
/* Content styling */
.ms-ai-audio-player-content {
    position: absolute;
    top: 20%;
}

.ms-ai-audio-player-logo {
    width: 50px;
    position: absolute;
    top: 10px;
    left: 10px;
}
button.ms-ai-audio-player-button.nav-icon svg {
    width: 12px;
    height: auto;
}
button.ms-ai-audio-player-button.nav-icon svg path {
    fill: #ccc;
}
button.ms-ai-audio-player-button.nav-icon:hover svg path {
    fill: white;
}
button.ms-ai-audio-player-button.play-btn img {
    width: 15px;
}
.ms-ai-audio-player-date {
    font-size: 11px;
    margin-bottom: 10px;
    color: #fff;
}

.ms-ai-audio-player-title {
    font-size: 14px;
    font-weight: bold;
    /* margin: 10px 0; */
    color: #fff;
}

.ms-ai-audio-player-subtitle {
    font-size: 12px;
    /* margin-top: 10px; */
    color: #fff;
}

/* Audio controls styling */
.ms-ai-audio-player-controls {
    bottom: 5px;
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    align-items: center;
}

.ms-ai-audio-player-button {
    background-color: initial;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ms-ai-time {
    color: #fff;
    display: flex;
    font-size: 10px;
    width: 100px;
}
.ms-ai-progress-container {
    background-color: #ccc;
    border-radius: 3px;
    cursor: pointer;
    height: 3px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.ms-ai-progress-bar {
    position: relative;
    width: 100%;
    height: 100%;
}

.ms-ai-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #ffffff; /* White progress */
    width: 0; /* Dynamically updated */
    transition: width 0.1s linear;
}

  
.ms-ai-audio-player-time {
    font-size: 14px;
}
.ms-ai-waveform-canvas {
    width: 100%;
    height: 100px;
    background-color: transparent;
    display: block;
    border-radius: 4px;
    margin-top: 10px;
}
canvas.ms-ai-waveform-canvas.hide {
    opacity: 0;
}
span.ms-ai-close-audioplayer {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 10px;
    cursor: pointer;
}
span.ms-ai-close-audioplayer svg {
    width: 10px;
}
span.ms-ai-close-audioplayer svg path {
    fill: #ccc;
}
span.ms-ai-close-audioplayer:hover svg path {
    fill: white;
}