.tc-msft-maintenance-mode {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 180px);
}

.tc-msft-container {
  text-align: center;
}

.tc-msft-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.tc-msft-text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
