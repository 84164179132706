
:root {
  --base-addressbar-outer-bg-color: #E9E9EB;
  --base-addressbar-outer-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.12), -2px -2px 8px 0px rgba(0, 0, 0, 0.12);
  --base-addressbar-inner-bg-color: #702573;
  --base-addressbar-inner-border-color: #D9D9D9;
  --base-addressbar-input-bg-color: #ffffff;
  --base-addressbar-send-btn-bg-color: hwb(298 15% 55%);
  --base-addressbar-send-btn-opacity-bg-color: rgba(112, 37, 115, 0.5);
  --base-addressbar-svg-icons-color: #FFFFFF;
  --base-addressbar-input-placeholder-color: #181C32;
  --base-addressbar-avatar-name-color: var(--base-addressbar-input-placeholder-color);
  --base-addressbar-dd-menu-arrow-color: #78788c;
  --base-addressbar-dd-menu-hover-color: #ffffff;
  --base-addressbar-dd-menu-item-color: #2C2C34;
}

.dark-mode {
  --base-addressbar-outer-bg-color: #212121;
  --base-addressbar-outer-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.12), -2px -2px 8px 0px rgba(0, 0, 0, 0.12);
  --base-addressbar-inner-bg-color: #702573;
  --base-addressbar-inner-border-color: #242424;
  --base-addressbar-input-bg-color: #2F2F2F;
  --base-addressbar-send-btn-bg-color: #702573;
  --base-addressbar-send-btn-opacity-bg-color: rgba(112, 37, 115, 0.5);
  --base-addressbar-svg-icons-color: #ffffff;
  --base-addressbar-input-placeholder-color: #ffffff;
  --base-addressbar-avatar-name-color: var(--base-addressbar-input-placeholder-color);
  --base-addressbar-dd-menu-arrow-color: #ffffff;
  --base-addressbar-dd-menu-hover-color: #232323;
  --base-addressbar-dd-menu-item-color: #ffffff;
}

.ms-ai-address-bar-container {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Segoe UI", "SegoeUI", sans-serif;
}

.ms-ai-talk-wrapper {
  width: 100%;
  /* padding: 5px; */
  background-color: var(--base-addressbar-outer-bg-color);
  border-radius: 8px;
  /* position: absolute; */
  bottom: 20px;
  /* box-shadow: var(--base-addressbar-outer-shadow); */
}

.ms-ai-talk-wrapper .ms-ai-talk-container {
  background-color: var(--base-addressbar-inner-bg-color);
  border: 2px solid var(--base-addressbar-inner-border-color);
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
}

.ms-ai-talk-wrapper form {
  margin: 0;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left {
  /* width: 270px; */
  padding-left: 16px;
  padding-right: 16px;
}
.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-details .ms-ai-talk-avatar-details-name{
  color: #FFF;
  text-align: justify;
  font-family: "Segoe UI", "SegoeUI", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block {
  position: relative;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7.5px 8px;
}

.ms-ai-talk-wrapper .ms-ai-talk-container.tc-open-menu .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container {
  background-color: var(--base-addressbar-inner-bg-color);
  border-bottom-left-radius: 28px;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50px;
  overflow: hidden;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-handle {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-details {
  width: calc(100% - 84px);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-details .ms-ai-talk-avatar-details-name {
  font-family: "Segoe UI", "SegoeUI", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.28px;
  color: var(--base-addressbar-avatar-name-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0;
  transition: all 0.4s ease-in-out;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-details .ms-ai-talk-avatar-details-designation {
  font-size: 13px;
  font-weight: 400;
  line-height: 17.29px;
  color: #78788C;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-dd-arrow {
  width: 24px;
  min-width: 24px;
  display: flex;
  cursor: pointer;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-dd-arrow svg {
  transform: rotate(-180deg);
  transition: transform 0.3s ease-in-out;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-dd-arrow svg path {
  fill: var(--base-addressbar-dd-menu-arrow-color);
}

.ms-ai-talk-wrapper .ms-ai-talk-container.tc-open-menu .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar-dd-arrow svg {
  transform: rotate(0deg);
}

.ms-ai-talk-avatar-dd-arrow {
  cursor: pointer;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu {
  position: absolute;
  left: -2px;
  bottom: 55px;
  z-index: 99;
  width: calc(100% - 2px);
  background-color: var(--base-addressbar-inner-bg-color);
  border: 2px solid var(--base-addressbar-inner-border-color);
  max-width: 272px;
  border-bottom: 0;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  overflow: hidden;
  /* display: none; */
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  box-sizing: content-box;
}

.ms-ai-talk-wrapper .ms-ai-talk-container.tc-open-menu .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu {
  /* display: block; */
  pointer-events: inherit;
  visibility: visible;
  opacity: 1;
}

.ms-ai-talk-wrapper .ms-ai-talk-container.tc-open-menu {
  border-top-left-radius: 0;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 177px;
  overflow-y: auto;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li {
  display: flex;
  align-items: center;
  padding: 9.5px;
  gap: 10px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.5s ease-in-out;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li:hover {
  background-color: var(--base-addressbar-dd-menu-hover-color);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li:hover .ms-ai-talk-avatar {
  transform: scale(1.2);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li .ms-ai-talk-avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50px;
  overflow: hidden;
  transform: scale(1);
  transition: transform cubic-bezier(0.25, 1, 0.5, 1) 0.3s;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li .ms-ai-talk-avatar-handle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li .ms-ai-talk-avatar img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li .ms-ai-talk-avatar-details .ms-ai-talk-avatar-details-name {
  font-family: "Segoe UI", "SegoeUI", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.28px;
  color: var(--base-addressbar-dd-menu-item-color);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list ul li .ms-ai-talk-avatar-details .ms-ai-talk-avatar-details-designation {
  font-size: 13px;
  font-weight: 400;
  line-height: 17.29px;
  color: #78788C;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list-search {
  background-color: #E4E4E7;
  padding: 14px 9.5px;
  display: flex;
  align-items: center;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-dd-menu .ms-ai-talk-avatar-dd-menu-list-search input {
  border: 0;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.6px;
  background-color: transparent;
  outline: none;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right {
  flex: 1 1;
  height: 48px;
  background-color: var(--base-addressbar-input-bg-color);
  display: flex;
  align-items: center;
  padding-right: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 5px;
  /* border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; */
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-attach {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-field {
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  height: 100%;
  flex: 1;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-field input {
  font-family: "Segoe UI", "SegoeUI", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--base-addressbar-input-placeholder-color);
  background-color: var(--base-addressbar-input-bg-color);
  width: 100%;
  border: 0;
  outline: none;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-field input::placeholder {
  color: var(--base-addressbar-input-placeholder-color);
  opacity: 0.5;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-field input::-ms-input-placeholder {
  color: var(--base-addressbar-input-placeholder-color);
  opacity: 0.5;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-sent-btn button {
  border: 0;
  background-color: var(--base-addressbar-send-btn-bg-color);
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.4s ease-in-out;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-microphone {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-microphone svg {
  width: 30px;
  height: auto;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-microphone svg g g {
  fill: var(--base-addressbar-send-btn-bg-color);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-sent-btn button:hover {
  background-color: var(--base-addressbar-send-btn-opacity-bg-color);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-sent-btn button.tc-btn-disabled {
  background-color: var(--base-addressbar-send-btn-opacity-bg-color);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-right .ms-ai-talk-input-wrapper .ms-ai-talk-input-sent-btn button svg path {
  fill: var(--base-addressbar-svg-icons-color);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar {
  position: relative;
  overflow: inherit;
  background-color: #fff;
  z-index: 9999;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar .avatar-container {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  z-index: 1;
  right: 0;
  bottom: 0;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container.tc-show-big-avatar .ms-ai-talk-avatar .avatar-container {
  transform: scale(2.5);
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container.tc-show-big-avatar .ms-ai-talk-avatar .avatar-container .tc-draggable-player {
  overflow: hidden;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container.tc-show-big-avatar .ms-ai-talk-avatar .avatar-container .tc-draggable-player::before {
  content: "";
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)); */
  background-color: var(--base-addressbar-outer-bg-color);
  position: absolute;
  z-index: -1;
  width: 50px;
  height: 50px;
  top: -5px;
  left: -5px;
  border-radius: 50%;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container:not(.tc-show-big-avatar) .ms-ai-talk-avatar .avatar-container .tc-draggable-player::before {
  background-image: none !important
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container.tc-show-big-avatar .ms-ai-talk-avatar .avatar-container .tc-draggable-player::before {
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar .avatar-container .tc-draggable-player img,
.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar .avatar-container .tc-draggable-player video {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container .ms-ai-talk-avatar .avatar-container .tc-draggable-player {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border: 0;
  box-shadow: none;
  border-radius: 50%;
  position: relative;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container.tc-show-big-avatar .ms-ai-talk-avatar .avatar-container .tc-draggable-player .avatar-idle-container,
.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container.tc-show-big-avatar .ms-ai-talk-avatar .avatar-container .tc-draggable-player .avatar-talking-container {
  border-radius: 50%;
  overflow: hidden;
  width: calc(100% - 5.5px);
  height: calc(100% - 5.5px);
  background-color: transparent;
}

.ms-ai-talk-wrapper .ms-ai-talk-container .ms-ai-talk-left .ms-ai-talk-avatar-block .ms-ai-talk-avatar-container.tc-show-big-avatar .ms-ai-talk-avatar-details .ms-ai-talk-avatar-details-name {
  padding-left: 28px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.96px;
}

.ms-ai-talk-label {
  color: var(--base-addressbar-avatar-name-color);
  text-align: justify;
  font-family: "Segoe UI", "SegoeUI", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 8px;
}

.avatar-placeholder.hidden {
  display: none;
}