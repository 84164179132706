.ms-ai-notfound-wrapper {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 141px);
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.ms-ai-notfound-wrapper .ms-ai-notfound-title {
    font-size: 30px;
    font-weight: 550;
}
.ms-ai-notfound-wrapper .ms-ai-notfound-description {
    font-size: 20px;
}