.ms-ai-audio-player-wrapper {
    width: 517px;
    min-height: 151px;
    padding: 10px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    gap: 20px;
}

.ms-ai-audio-player-wrapper .ms-ai-audio-player-thumbnail {
    width: 137px;
    /* height: 100%; */
    object-fit: cover;
}
.ms-ai-audio-player-wrapper .ms-ai-audio-player-thumbnail img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.ms-ai-audio-player-wrapper .ms-ai-audio-player-title {
    font-size: 19px;
    font-weight: 550;
}

.ms-ai-audio-player-wrapper .ms-ai-audio-player-subtitle {
    font-size: 16px;
    font-weight: 500;
}
.ms-ai-audio-player-controls {
    padding-top: 20px;
}

.ms-ai-time-current, .ms-ai-time-total {
    font-size: 12px;
    color: #7A7C7E;
}
span.ms-ai-time-total {
    float: right;
}
.ms-ai-audio-content-area {
    width: calc(100% - 157px);
    padding-right: 20px;
}
.ms-ai-progress-container {
    background-color: #CFCFCF;
    border-radius: 3px;
    cursor: pointer;
    height: 3px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.ms-ai-progress-bar {
    position: relative;
    width: 100%;
    height: 100%;
}

.ms-ai-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #702573; /* White progress */
    width: 0; /* Dynamically updated */
    transition: width 0.1s linear;
}

button.ms-ai-audio-player-button.nav-icon {
    background: none;
    border: none;
    display: flex;
}
button.ms-ai-audio-player-button.play-btn {
    background: #702573;
    border: none;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    padding-right: 5px;
}
.ms-ai-audio-player-buttons button {
    cursor: pointer;
}

button.ms-ai-audio-player-button.nav-icon {
    padding-left: 0;
    padding-right: 0;
}
button.ms-ai-audio-player-button.volumn-button svg path {
    fill: #702573;
    stroke: #702573;
}
button.ms-ai-audio-player-button.volumn-button {
    background: none;
    border: none;
    display: flex;
}
.ms-ai-audio-player-buttons {
    gap: 10px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures spacing */
  }
  
  .ms-ai-audio-player-button.volumn-button {
    margin-left: auto; /* Pushes this button to the right */
    padding-right: 0;
  }