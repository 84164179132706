ol.ms-ai-ol-list {
    padding-left: 18px;
}
ol.ms-ai-ol-list li {
    margin-bottom: 10px; /* Adjust the value to your desired gap size */
}

ol.ms-ai-ol-list li:last-child {
    margin-bottom: 0; /* Remove the margin from the last list item */
}
.mi-ai-stories-container {
    display: flex;
  }
  
  .mi-ai-stories-content {
    display: flex;
    max-width: 1200px;
    width: 100%;
  }
  
  .mi-ai-stories-win-snapshot {
    margin-right: 24px;
  }
  
  .mi-ai-stories-main-content {
    flex: 2;
  }
  
  .mi-ai-stories-solution,
  .mi-ai-stories-why-we-won {
    margin-bottom: 20px;
  }
  
  .mi-ai-stories-win-snapshot .mi-ai-stories-win-content strong {
    color: #2A446F;
  }
  
  p {
    margin: 5px 0;
  }
  
 .mi-ai-stories-list {
    padding-left: 15px;
}
  
  .mi-ai-stories-list li {
    margin-bottom: 6px;
  }
  .mi-ai-stories-win-content {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    font-family: "Helvetica Neue LT W05_65 Medium";
}
.mi-ai-stories-win-content p,
.mi-ai-stories-win-content p .mi-ai-stories-list{
  font-size: 13px!important;
  margin-bottom: 10px !important;
  line-height: normal;
}



.mi-ai-stories-win-snapshot h2,
.mi-ai-stories-main-content h2 {
  font-family: "Helvetica Neue LT W05_65 Medium";
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
  margin-bottom: 16px;
}

.mi-ai-stories-win-snapshot p,
.mi-ai-stories-main-content p,
.mi-ai-stories-main-content ul,
.mi-ai-stories-win-snapshot ul {
  margin: 10px 0;
  color: #555;
  font-family: "Helvetica Neue LT W05_45 Light";
  font-size: 15.84px;
  line-height: 24px;
  color: #17253D;
}

.mi-ai-stories-list {
  padding-left: 20px;
  margin: 10px 0;
}

.mi-ai-stories-list li {
  margin-bottom: 6px;
}

.mi-ai-stories-main-content ul.mi-ai-stories-list li strong {
  font-weight: bold;
}

.ms-ai-wrapper .ms-ai-container .ms-ai-inner-page-wrapper .ms-ai-inner-page-container .ms-ai-inner-page-right .layout-sub-item p{
  font-family: "Helvetica Neue LT W05_45 Light";
  font-size: 15.84px;
  line-height: 24px;
  color: #17253D;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.mi-ai-stories-center-quote {
  text-align: center;
  margin-bottom: 20px;
}
.mi-ai-stories-center-quote .mi-ai-stories-center-quote-title {
  font-weight: 500;
  font-size: 17px;
}
.mi-ai-stories-center-quote .mi-ai-stories-center-quote-subtitle {
  font-weight: 400;
  font-size: 15px;
}